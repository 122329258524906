import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './Header.module.scss'
import Logo from './Logo'
import { ReactComponent as SchlafometerIcon } from '../../images/schlafometer-icon.svg'
import Navigation from './Navigation'
import { Link } from 'react-router-dom'

const Header = (props) => {
  return (
    <header>
      <Container fluid className={styles.header+" w-100"}>
        <Row className={styles.headerRow}>
          <Col lg={10} sm={6} xs={10} className="d-flex">
            <Link to="/" className={styles.schlafometerSlogan} >
              <SchlafometerIcon className={styles.smIcon} aria-label="Schlafometer Icon" />
              <div className={styles.smTitle}>
                <span>VBG Schlafometer</span>
                <span className={styles.smSlogan}>Selbstcheck für Schichtarbeitende</span>
              </div> 
            </Link>
            <Navigation smStarted={props.smStarted} smEnded={props.smEnded} />
          </Col>
          <Col lg={2} sm={6} xs={2} className="d-flex justify-content-end">
            <Logo/>
          </Col>
        </Row>
      </Container>
    </header>
    
  )
}

export default Header