import React, { lazy, Suspense, useEffect, useState } from 'react';
import Header from './pages/components/Header'
import { Container } from 'react-bootstrap';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Loading from './pages/components/Loading';
import Footer from './pages/components/Footer';
import NavRow from './pages/components/NavRow';
import { Questions } from './data/data'
import { getAllQuestionsAnswered, schlafometerStarted, userDataDefault, checkUrlParamData, userDataToCsvData, implementStats } from './js/Tools';
import ErrorBoundary from './pages/components/ErrorBoundary';

function App() {
  const Home = lazy(() => import('./pages/Home'));
  const Schlafometer = lazy(() => import('./pages/Schlafometer'));
  const SchlafometerInfos = lazy(() => import('./pages/SchlafometerInfos'));
  const Unternehmen = lazy(() => import('./pages/Unternehmen'));
  const BesserSchlafen = lazy(() => import('./pages/BesserSchlafen'));
  const NotFound = lazy(() => import('./pages/NotFound'));
  const Kontakt = lazy(() => import('./pages/Kontakt'));
  // const Impressum = lazy(()=> import('./pages/Impressum'));
  const Datenschutz = lazy(() => import('./pages/Datenschutz'));
  const Barrierefreiheit = lazy(() => import('./pages/Barrierefreiheit'));
  const SaveProgress = lazy(() => import('./pages/SaveProgress'));
  // const Tester = lazy(()=> import('./pages/Tester'));

  const [schlafometerActive, setSchlafometerActive] = useState(false);
  const path = useLocation().pathname;
  const hash = useLocation().hash;
  const parameter = useLocation().search

  // matomo
  var _paq = window._paq = window._paq || [];
  (function () {
    var u = "https://matomo.jedermann.de/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '5']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
  })();

  useEffect(() => {
    if (parameter) {
      try {
        let linkData = decodeURI(parameter).substring(6, parameter.length).replaceAll("%22", '"')
        let linkDataObj = JSON.parse(linkData)
        if (decodeURI(parameter).includes("data") && checkUrlParamData(linkDataObj)) {
          setUserData(linkDataObj)
        }
        else {
          console.error("data from url parameter incorrect")
        }
      } catch (error) {
        console.error("error while decoding uri " + error);
      }
    }
  }, [parameter])

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const targetElement = document.getElementById(hash.substring(1))
        // console.log(targetElement)
        targetElement?.scrollIntoView({ behavior: 'smooth' })
      }, 500);
    }
  }, [hash])

  useEffect(() => {
    // scroll top
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 10);

    // doc title
    const siteTitles = [
      { "path": "/home", "title": "VBG Schlafometer | Home" },
      { "path": "/unternehmen", "title": "VBG Schlafometer | Für Unternehmen" },
      { "path": "/besser-schlafen", "title": "VBG Schlafometer | Besser schlafen" },
      { "path": "/schlafen", "title": "VBG Schlafometer | Besser schlafen" },
      { "path": "/schlafometer", "title": "VBG Schlafometer | Schlafometer" },
      { "path": "/schlafometer-infos", "title": "VBG Schlafometer | Was ist das?" },
      { "path": "/kontakt", "title": "VBG Schlafometer | Kontakt" },
      { "path": "/impressum", "title": "VBG Schlafometer | Impressum" },
      { "path": "/datenschutz", "title": "VBG Schlafometer | Datenschutz" },
      { "path": "/barrierefreiheit", "title": "VBG Schlafometer | Barrierefreiheit" },
      { "path": "/404", "title": "VBG Schlafometer | Seite nicht gefunden" },
      { "path": "/tester", "title": "VBG Schlafometer | Tester" },
      { "path": "/speichern-laden", "title": "VBG Schlafometer | Speichern / Laden" }
    ];
    const title = "VBG Schlafometer";
    if (path) {
      let currentTitle = siteTitles.filter(i => i.path === path);
      if (currentTitle[0] !== undefined) {
        document.title = currentTitle[0].title;
      } else {
        document.title = title;
      }
    }

    // stats (matomo)
    _paq.push(["disableCookies"]);
    _paq.push(['setCustomUrl', encodeURI(window.location.href)]);
    _paq.push(['setDocumentTitle', document.title]);
    _paq.push(['trackPageView']);
    // stats (logfile)
    implementStats();

    // set background
    if (path === "/schlafometer") {
      setSchlafometerActive(true)
    }
    else {
      setSchlafometerActive(false)
    }
  }, [path])

  const [userData, setUserData] = useState(userDataDefault);
  const [schlafometerHasStarted, setSchlafometerHasStarted] = useState(false);
  const [schlafometerHasEnded, setSchlafometerHasEnded] = useState(false);

  useEffect(() => {
    setSchlafometerHasStarted(schlafometerStarted(userData.results))
    setSchlafometerHasEnded(getAllQuestionsAnswered(userData.results, Questions.questionnaire))
  }, [userData]);

  const setUserDataStartApi = (alter, sent, rating, activeView) => {
    var newUserData = Object.fromEntries(Object.entries(userData).slice());
    if (alter !== false) {
      newUserData["alter"] = alter;
    }
    if (sent !== false) {
      newUserData["sent"] = sent;
    }
    if (rating !== false) {
      newUserData["rating"] = rating;
    }
    if (activeView !== false) {
      newUserData["activeView"] = activeView;
    }
    setUserData(newUserData);

    // if sent is set push to csv
    if (newUserData.sent) {
      var data = { "csvData": userDataToCsvData(newUserData, Questions) };
      fetch("/saveCsv/saveCsv.php", {
        method: "POST",
        mode: "same-origin",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.text())
        .then((data) => console.info(data))
        .catch((e) => console.error("Fetching saveCsv resulted in error: " + e))
    }
  }

  const setValueToUserData = (value, key) => {
    var newUserData = Object.fromEntries(Object.entries(userData).slice());
    newUserData[key] = value;
    setUserData(newUserData);
  }

  const setAnswerToUserData = (answer, value, activeView, questionnaire, question, arbeitszeit, schicht) => {
    var newUserData = Object.fromEntries(Object.entries(userData).slice());
    newUserData.activeView = activeView;
    newUserData.questionnaire = questionnaire;
    newUserData.question = question;
    newUserData.arbeitszeit = arbeitszeit;
    newUserData.schicht = schicht;
    if (activeView === "question") {
      newUserData.uiData[questionnaire][question] = answer;
      newUserData.results[questionnaire][question] = value;
    }
    else {
      newUserData[activeView] = answer;
    }
    setUserData(newUserData);
  }

  const setNewUserData = (newData) => {
    let newUserData = Object.fromEntries(Object.entries(userData).slice());
    newUserData.results = newData.results
    newUserData.uiData = newData.uiData
    newUserData.arbeitszeit = newData.arbeitszeit
    newUserData.schicht = newData.schicht
    newUserData.alter = newData.alter
    if (getAllQuestionsAnswered(newData.results, Questions.questionnaire)) {
      newUserData.activeView = "result"
      newUserData.questionnaire = "CSM"
      newUserData.question = 12
    }
    else if (schlafometerStarted(newData.results)) {
      newUserData.activeView = "question"
    }
    setUserData(newUserData);
  }

  const navigate = useNavigate();

  const RedirectUrl = ({ url }) => {
    useEffect(() => {
      if (path === "/impressum") {
        navigate('/home')
        window.location.href = url;
      }
      else {
        window.open(url, '_blank', 'noopener,noreferrer').focus();
      }
    }, [url]);

    return <h5>Seite wird weitergeleitet...</h5>;
  };

  return (
    <div className={(schlafometerActive) ? "App bg-blue" : "App"}>
      <Header smStarted={schlafometerHasStarted} smEnded={schlafometerHasEnded} />
      <main>
        <Container className='mt-3' style={{ flex: "1 0 auto" }}>
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Routes>
                <Route path="/" element={<Home smStarted={schlafometerHasStarted} smEnded={schlafometerHasEnded} />} />
                <Route path="/home" element={<Home smStarted={schlafometerHasStarted} smEnded={schlafometerHasEnded} />} />
                <Route path="/unternehmen" element={<Unternehmen />} />
                <Route path="/besser-schlafen" element={<BesserSchlafen />} />
                <Route path="/schlafen" element={<BesserSchlafen />} />
                <Route path="/schlafometer" element={<Schlafometer userData={userData} setUserData={setAnswerToUserData} setValueToUserData={setValueToUserData} setUserDataStartApi={setUserDataStartApi} />} />
                <Route path="/schlafometer-infos" element={<SchlafometerInfos />} />
                <Route path="/kontakt" element={<Kontakt />} />
                <Route path="/impressum" element={<RedirectUrl url="https://www.vbg.de/DE/Footer/Impressum/impressum_node.html" />} />
                {/* <Route path="/impressum" element={<Impressum/>}/> */}
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/barrierefreiheit" element={<Barrierefreiheit />} />
                <Route path="/404" element={<NotFound />} />
                {/* <Route path="/tester" element={<Tester userData={userData} setUserData={setUserData}/>}/> */}
                <Route path="/speichern-laden" element={<SaveProgress userData={userData} setUserData={setNewUserData} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </Container>
        {(schlafometerHasStarted && !schlafometerActive) &&
          <NavRow
            userData={userData}
            action={() => { navigate('/schlafometer') }}
          />
        }
      </main>
      <Footer />
    </div>
  );
}

export default App;

