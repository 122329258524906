import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './ButtonRow.module.scss'

const ButtonRow = (props) => {
  return (
    <nav aria-label="Navigation innerhalb des Schlafometers">
      <Row>
        <Col className={styles.buttons+' d-flex justify-content-between '+props.className}>
          {props.children}
        </Col>
      </Row>
    </nav>
  )
}

export default ButtonRow