import React from 'react';
import "./Logo.scss";
import VBGlogo from '../../images/logo_vbg.svg'

const Logo = () => {
  return (
    <div className="logo-wrapper"> 
        <a className="vbg-logo navbar-btn" href="https://www.vbg.de/" title='zur VBG Website (öffnet in einem neuen Fenster)' target='_blank' rel='noreferrer'>
            <img alt="VBG Ihre gesetzliche Unfallversicherung" src={VBGlogo} width={220} height={86} />
        </a>
    </div>
  )
}

export default Logo
