import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai'

const Loading = () => {
    return (
        <div className='loading-animation'>
            <AiOutlineLoading aria-hidden="true" className='spinner-icon'/>
            <span className='sr-only'>Loading</span>
        </div> 
    )
}

export default Loading