// import React from "react";

export const Questions = {
    "questionnaire":{
        "ESS": {
            "id": "ESS",
            "name": "ESS-Tagesmüdigkeit",
            "nameshort": "Tagesschläfrigkeit",
            "intro": "Mit diesem Fragebogen erhalten Sie eine Einschätzung zum Grad Ihrer Tagesschläfrigkeit.",
            "description": "Die folgenden Fragen beziehen sich auf Ihr normales Alltagsleben in der letzten Zeit. Stellen Sie sich die Situationen in der Freizeit außerhalb Ihrer Arbeitszeit vor. Für wie wahrscheinlich halten Sie es, dass Sie in einer der folgenden Situationen einnicken oder einschlafen würden, sich also nicht nur müde fühlen? Auch wenn Sie in der letzten Zeit einige dieser Situationen nicht erlebt haben, versuchen Sie sich trotzdem vorzustellen, wie sich diese Situationen auf Sie ausgewirkt hätten.",
            "text": "Wissenschaftliche Untersuchungen von Verkehrsunfällen zeigen, dass einige Menschen tagsüber für wenige Sekunden einschlafen. Das kann bei betrieblicher Fahrtätigkeit und beim Arbeiten an Maschinen oder auf dem Weg zur Arbeit zu schweren Unfällen führen. Mit dem Test erfahren Sie, ob Sie tagsüber ausreichend wach und munter sind. Die Fragen beziehen sich auf Ihren Alltag.",
            "instruction": "",
            "timeest": "ca. 5 Minuten",
            "icon": "ess-icon",
            "image": "schlafometer-ess",
            "questions":[
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Sie lesen im Sitzen",
                    "image": {
                        "name": "ess-lesen",
                        "alt": "Frau sitzt in einem Sessel und liest ein Buch"
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                },
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Beim Fernsehen",
                    "image": {
                        "name": "ess-fernsehen",
                        "alt": "Eine Person sitzt mit Popcorn auf der Couch."
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                },
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Wenn Sie passiv (als Zuhörer) in der Öffentlichkeit sitzen (z.B. im Theater oder bei einem Vortrag)",
                    "image": {
                        "name": "ess-zuhoeren",
                        "alt": "Mehrere Personen sitzen in einem Vortrag, Frau am Rednerpult"
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                },
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Als Beifahrer im Auto während einer einstündigen Fahrt ohne Pause",
                    "image": {
                        "name": "ess-beifahrer",
                        "alt": "Zwei Personen in einem Auto"
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                },
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Wenn Sie sich am Nachmittag hingelegt haben, um auszuruhen",
                    "image": {
                        "name": "ess-ausruhen",
                        "alt": "Mann liegt auf Sofa"
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                },
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Wenn Sie sitzen und sich mit jemandem unterhalten",
                    "image": {
                        "name": "ess-unterhalten",
                        "alt": "Zwei Personen sitzen an einem Tisch und unterhalten sich."
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                },
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Wenn Sie nach dem Mittagessen (ohne Alkohol) ruhig dasitzen",
                    "image": {
                        "name": "ess-sitzen",
                        "alt": "Mann sitzend"
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                },
                {
                    "intro": "Stellen Sie sich die folgende Situation vor:",
                    "question": "Für wie wahrscheinlich halten Sie es, dass Sie in dieser Situation einnicken oder einschlafen würden, sich also nicht nur müde fühlen?",
                    "explanation": "Wenn Sie als Fahrer eines Autos verkehrsbedingt einige Minuten halten müssen",
                    "image": {
                        "name": "ess-auto",
                        "alt": "Auto wartet vor Ampel"
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "niemals", "value": 0, "img": "eye-open" },
                        { "text": "geringe Wahrscheinlichkeit", "value": 1, "img":"eye-mid-open" },
                        { "text": "mittlere Wahrscheinlichkeit", "value": 2, "img":"eye-mid-closed" },
                        { "text": "hohe Wahrscheinlichkeit", "value": 3, "img":"eye-closed" }
                    ]
                }
            ],
        },
        "RIS": {
            "id": "RIS",
            "name": "RIS-Schlafqualität",
            "nameshort": "Schlafqualität",
            "intro": "Mit diesem Fragebogen erhalten Sie eine Einschätzung zu Ihrer Schlafqualität.",
            "description": "Mit dem folgenden Test können Sie herausfinden, ob Sie gut schlafen. Bitte beantworten Sie die Fragen, indem Sie die zutreffende Antwort anklicken. Es gibt je Frage nur eine Antwort-Möglichkeit. Die Fragen beziehen sich auf die letzten vier Wochen. Bewerten Sie Ihre üblichen Schlafperioden nachts (nicht den Tagschlaf nach Nachtschichten!).",
            "text": "Die meisten Menschen schlafen abends zügig ein und wachen morgens erholt auf. In einer Befragung gab jedoch knapp ein Drittel der Erwerbstätigen an, dass sie nachts nicht gut schlafen, bei Beschäftigten in Wechselschicht war es sogar die Hälfte der Befragten. Mit diesem Test finden Sie heraus, wie gut Sie tatsächlich ein- und durchschlafen. Die Fragen beziehen sich auf Ihren Nachtschlaf in den letzten vier Wochen.",
            "instruction": "",
            "timeest": "ca. 5 Minuten",
            "icon": "ris-icon",
            "image": "schlafometer-ris",
            "questions":[
                {
                    "intro": "Klicken Sie die passende Antwort an.",
                    "question": "Wie viele Minuten brauchen Sie in der Regel um einzuschlafen?",
                    "explanation": "",
                    "image": {
                        "name": "ris-schlafen",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "1-20 Minuten", "value": 0 },
                        { "text": "20-40 Minuten", "value": 1 },
                        { "text": "40-60 Minuten", "value": 2 },
                        { "text": "60-90 Minuten", "value": 3 },
                        { "text": "mehr als 90 Minuten", "value": 4 }
                    ]
                },
                {
                    "intro": "Klicken Sie die passende Antwort an.",
                    "question": "Wie viele Stunden glauben Sie, durchschnittlich nachts zu schlafen?",
                    "explanation": "",
                    "image": {
                        "name": "ris-schlaf",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "7 Stunden und mehr", "value": 0 },
                        { "text": "5-6 Stunden", "value": 1 },
                        { "text": "4 Stunden", "value": 2 },
                        { "text": "2-3 Stunden", "value": 3 },
                        { "text": "0-1 Stunden", "value": 4 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich kann nicht durchschlafen",
                    "image": {
                        "name": "ris-wach-wecker",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 4 },
                        { "text": "meistens", "value": 3 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 1 },
                        { "text": "nie", "value": 0 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich wache zu früh auf",
                    "image": {
                        "name": "ris-aufstehen",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 4 },
                        { "text": "meistens", "value": 3 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 1 },
                        { "text": "nie", "value": 0 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich wache schon bei leichten Geräuschen auf",
                    "image": {
                        "name": "ris-aufwachen",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 4 },
                        { "text": "meistens", "value": 3 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 1 },
                        { "text": "nie", "value": 0 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich habe das Gefühl, die ganze Nacht kein Auge zugetan zu haben",
                    "image": {
                        "name": "ris-wach-schafe",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 4 },
                        { "text": "meistens", "value": 3 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 1 },
                        { "text": "nie", "value": 0 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich denke viel über meinen Schlaf nach",
                    "image": {
                        "name": "ris-nachdenken",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 4 },
                        { "text": "meistens", "value": 3 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 1 },
                        { "text": "nie", "value": 0 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich habe Angst ins Bett zu gehen, da ich befürchte, nicht schlafen zu können",
                    "image": {
                        "name": "ris-angst",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 4 },
                        { "text": "meistens", "value": 3 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 1 },
                        { "text": "nie", "value": 0 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich fühle mich voll leistungsfähig",
                    "image": {
                        "name": "ris-leistung",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 0 },
                        { "text": "meistens", "value": 1 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 3 },
                        { "text": "nie", "value": 4 }
                    ]
                },
                {
                    "intro": "Diese Aussage bezieht sich auf Ihren Nachtschlaf in den letzten vier Wochen:",
                    "question": "Wie stark trifft das auf Sie zu?",
                    "explanation": "Ich nehme Schlafmittel, um einschlafen zu können",
                    "image": {
                        "name": "ris-schlafmittel",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "immer", "value": 4 },
                        { "text": "meistens", "value": 3 },
                        { "text": "manchmal", "value": 2 },
                        { "text": "selten", "value": 1 },
                        { "text": "nie", "value": 0 }
                    ]
                }
            ]
        },
        "CSM": {
            "id": "CSM",
            "name": "CSM-Chronotypfragebogen",
            "nameshort": "Chronotyp",
            "intro": "Lerche oder Eule? Mit diesem Fragebogen erhalten Sie Auskunft zu Ihrem Chronotyp.",
            "description": "Mit dem folgenden Fragebogen finden Sie heraus, ob Sie eher ein Morgen- oder ein Abendmensch sind. Versetzen Sie sich vor der Beantwortung der Fragen in eine Zeit, in der Ihr Alltag nicht von der Schichtarbeit bestimmt wird. Wie ist es, wenn Sie einige Tage oder Wochen frei haben? In welchem Tag-Nacht-Rhythmus würden Sie dann leben?",
            "text": "Die meisten Menschen sind vormittags und nachmittags wach und aktiv. Einige Menschen stehen gerne früh auf. Die sogenannten Lerchen sind eher morgens und vormittags aktiv. Andere Menschen stehen gerne spät auf. Die sogenannten Eulen sind eher nachmittags und abends aktiv. Mit dem Fragebogen zum Chronotyp finden Sie heraus, ob Sie ein Morgenmensch oder ein Abendmensch sind. Die Fragen beziehen sich auf Ihren Alltag.",
            "instruction": "Überlegen Sie nur kurz und antworten Sie spontan. Bitte wählen Sie jeweils nur eine Antwort aus.",
            "timeest": "ca. 10 Minuten",
            "icon": "/src/resources/img/icons",
            "image": "schlafometer-csm",
            "questions":[
                {
                    "intro": "",
                    "question": "Wann würden Sie aufstehen, wenn es nur nach Ihrem eigenen Wohlbefinden ginge und Sie sich den Tag frei einteilen könnten?",
                    "explanation": "",
                    "image": {
                        "name": "csm-aufstehen",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers":  [
                        { "text": "vor 6:30 Uhr", "value": 5 },
                        { "text": "zwischen 6:30 Uhr und 7:45 Uhr", "value": 4 },
                        { "text": "zwischen 7:45 Uhr und 9:45 Uhr", "value": 3 },
                        { "text": "zwischen 9:45 Uhr und 11 Uhr", "value": 2 },
                        { "text": "nach 11 Uhr", "value": 1 }
                    ]
                },
                {
                    "intro": "",
                    "question": "Wann würden Sie zu Bett gehen, wenn es nur nach Ihrem eigenen Wohlbefinden ginge und Sie sich den Abend frei einteilen könnten?",
                    "explanation": "",
                    "image": {
                        "name": "csm-schlafdauer",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers":  [
                        { "text": "vor 21 Uhr", "value": 5 },
                        { "text": "zwischen 21 Uhr und 22:15 Uhr", "value": 4 },
                        { "text": "zwischen 22:15 Uhr und 0:30 Uhr", "value": 3 },
                        { "text": "zwischen 0:30 Uhr und 1:45 Uhr", "value": 2 },
                        { "text": "nach 1:45 Uhr", "value": 1 }
                    ]
                },
                {
                    "intro": "",
                    "question": "Wie leicht fällt es Ihnen normalerweise morgens aufzustehen?",
                    "explanation": "",
                    "image": {
                        "name": "csm-aufstehen2",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "überhaupt nicht so leicht", "value": 1, "img":"smiley" },
                        { "text": "nicht so leicht", "value": 2, "img":"smiley" },
                        { "text": "ziemlich leicht", "value": 3, "img":"smiley" },
                        { "text": "sehr leicht", "value": 4, "img":"smiley" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Wie wach fühlen Sie sich morgens in der ersten halben Stunde nach dem Aufwachen?",
                    "explanation": "",
                    "image": {
                        "name": "csm-aufstehen3",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "überhaupt nicht wach", "value": 1, "img":"smiley" },
                        { "text": "etwas wach", "value": 2, "img":"smiley" },
                        { "text": "ziemlich wach", "value": 3, "img":"smiley" },
                        { "text": "sehr wach", "value": 4, "img":"smiley" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Wie müde fühlen Sie sich morgens in der ersten halben Stunde nach dem Aufwachen?",
                    "explanation": "",
                    "image": {
                        "name": "csm-muede",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "sehr müde", "value": 1, "img":"smiley" },
                        { "text": "ziemlich müde", "value": 2, "img":"smiley" },
                        { "text": "ziemlich fit", "value": 3, "img":"smiley" },
                        { "text": "sehr fit", "value": 4, "img":"smiley" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Sie haben beschlossen, Sport zu treiben. Freunde schlagen vor, zweimal wöchentlich eine Stunde zu trainieren. Für Ihre Freunde ist die beste Zeit Sport zu treiben morgens zwischen 7 und 8 Uhr. Wenn Sie von Ihrem eigenen Wohlbefinden ausgehen, wie schätzen Sie Ihre Leistungsfähigkeit zu dieser Zeit ein?",
                    "explanation": "",
                    "image": {
                        "name": "csm-train",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "Ich wäre gut in Form.", "value": 4, "img":"smiley" },
                        { "text": "Ich wäre ziemlich in Form.", "value": 3, "img":"smiley" },
                        { "text": "Es wäre ziemlich schwierig für mich.", "value": 2, "img":"smiley" },
                        { "text": "Es wäre sehr schwierig für mich.", "value": 1, "img":"smiley" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Wann werden Sie abends müde und möchten deshalb schlafen gehen?",
                    "explanation": "",
                    "image": {
                        "name": "csm-muede2",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "vor 21 Uhr", "value": 5, "img":"" },
                        { "text": "zwischen 21 Uhr und 22:15 Uhr", "value": 4, "img":"" },
                        { "text": "zwischen 22:15 Uhr und 0:30 Uhr", "value": 3, "img":"" },
                        { "text": "zwischen 0:30 Uhr und 1:45 Uhr", "value": 2, "img":"" },
                        { "text": "nach 1:45 Uhr", "value": 1, "img":"" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Für eine schriftliche Prüfung, die sehr anstrengend ist und 2 Stunden dauert, möchten Sie in Bestform sein. Sie können sich Ihren Tag völlig frei einteilen. Welchen Zeitraum würden Sie auswählen?",
                    "explanation": "",
                    "image": {
                        "name": "csm-exam",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "von 8 bis 10 Uhr", "value": 4, "img":"" },
                        { "text": "von 11 bis 13 Uhr", "value": 3, "img":"" },
                        { "text": "von 15 bis 17 Uhr", "value": 2, "img":"" },
                        { "text": "von 19 bis 21 Uhr", "value": 1, "img":"" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Manche Menschen sind Morgentypen, andere dagegen Abendtypen. Zu welchem Typ würden Sie sich zählen?",
                    "explanation": "",
                    "image": {
                        "name": "csm-morgenabendtyp",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "eindeutig „Morgentyp“", "value": 4, "img":"" },
                        { "text": "eher „Morgentyp“ als „Abendtyp“", "value": 3, "img":"" },
                        { "text": "eher „Abendtyp“ als „Morgentyp“", "value": 2, "img":"" },
                        { "text": "eindeutig „Abendtyp“", "value": 1, "img":"" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Wann würden Sie aufstehen, wenn Sie einen ganzen Tag Seminare bzw. einen Arbeitstag vor sich hätten (8 Stunden) und Sie sich die Zeit völlig frei einteilen könnten?",
                    "explanation": "",
                    "image": {
                        "name": "csm-aufstehen-fabrik",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "vor 6:30 Uhr", "value": 4, "img":"" },
                        { "text": "zwischen 6:30 Uhr und 7:30 Uhr", "value": 3, "img":"" },
                        { "text": "zwischen 7:30 Uhr und 8:30 Uhr", "value": 2, "img":"" },
                        { "text": "nach 8:30 Uhr", "value": 1, "img":"" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Stellen Sie sich vor, Sie müssten jeden Morgen um 6:00 Uhr aufstehen. Wie wäre das für Sie?",
                    "explanation": "",
                    "image": {
                        "name": "csm-wecker",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "sehr schwierig und unangenehm", "value": 1, "img":"" },
                        { "text": "ziemlich schwierig und unangenehm", "value": 2, "img":"" },
                        { "text": "etwas unangenehm, aber kein größeres Problem", "value": 3, "img":"" },
                        { "text": "einfach und nicht unangenehm", "value": 4, "img":"" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Wie lange dauert es bei Ihnen morgens nach dem Aufstehen, bis Sie einen klaren Kopf haben und klar denken können?",
                    "explanation": "",
                    "image": {
                        "name": "csm-klardenken",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "0 bis 10 Minuten", "value": 4, "img":"" },
                        { "text": "11 bis 20 Minuten", "value": 3, "img":"" },
                        { "text": "21 bis 40 Minuten", "value": 2, "img":"" },
                        { "text": "mehr als 40 Minuten", "value": 1, "img":"" }
                    ]
                },
                {
                    "intro": "",
                    "question": "Sind Sie eher morgens oder abends aktiv?",
                    "explanation": "",
                    "image": {
                        "name": "csm-abendmorgenaktiv",
                        "alt": ""
                    },
                    "type": "choose-one",
                    "answers": [
                        { "text": "ausgesprochen morgens aktiv (morgens wach, abends müde)", "value": 4, "img":"" },
                        { "text": "eher morgens aktiv", "value": 3, "img":"" },
                        { "text": "eher abends aktiv", "value": 2, "img":"" },
                        { "text": "ausgesprochen abends aktiv (morgens müde, abends wach)", "value": 1, "img":"" }
                    ]
                }
            ]
        }
    },
}

export const Results = {
    "ESS": {
        "name": "Tagesschläfrigkeit",
        "intro": "Wenn Sie sich tagsüber manchmal müde fühlen, ist das noch kein Problem. Auch Stress und anstrengende körperliche Arbeit können tagsüber müde machen. Häufige Tagesmüdigkeit, die Ihre Konzentration und Leistungsfähigkeit beeinträchtigt, kann auf eine Schlafstörung hinweisen. Dieser Selbsttest ersetzt keine ärztliche Diagnose.",
        "none": {
            "label": "keine Anzeichen für Tagesschläfrigkeit",
            "labelShort": "keine",
            "img": "ess-result-none",
            "text": ["Vermutlich fühlen Sie sich tagsüber wach und ausgeschlafen. Damit das so bleibt, schlafen Sie ausreichend: es sollten durchschnittlich mindestens sieben Stunden innerhalb eines 24-Stunden-Tages sein. Prüfen Sie auch, welche Tipps für den gesunden Schlaf Sie bereits umsetzen. Berücksichtigen Sie dabei Ihr Schlafbedürfnis und Ihren Chronotyp und denken Sie daran, sich ausreichend zu bewegen."], 
            "score": {
                "min": 0, 
                "max": 10
            }
        },
        "mid": {
            "label": "leicht erhöhte Tagesschläfrigkeit",
            "labelShort": "leicht erhöht",
            "img": "ess-result-mid",
            "text": [
                "Wie macht sich die Tagesmüdigkeit bei Ihnen bemerkbar? Betroffene klagen häufig über Schlappheit, Mangel an Energie, Erschöpfung und Schläfrigkeit. Tagesmüdigkeit kann viele Gründe haben. Der Schlaf spielt eine entscheidende Rolle, aber auch Erkrankungen, Medikamente oder psychische Belastungen können zu Erschöpfung und Müdigkeit am Tag führen. Die Lage der Arbeitszeit kann das Risiko für Tagesmüdigkeit erhöhen: für Beschäftigte, die früh morgens oder nachts arbeiten müssen, ist es besonders wichtig, auf ausreichenden Schlaf zu achten. Denken Sie daran, sich draußen bei Tageslicht ausreichend zu bewegen.",
                "Versuchen Sie auch bei Schichtarbeit durchschnittlich mindestens sieben Stunden innerhalb eines 24-Stunden-Tages zu schlafen. Der Zeitstrahl für Ihren Tagesablauf mit geeigneten Schlafzeiten und die Tipps zum besseren Schlafen können Ihnen dabei helfen. Möglicherweise hilft Ihnen an manchen Tagen auch ein kurzes Nickerchen.",
                "Wenn Sie das Gefühl haben, dass die Tagesmüdigkeit Ihre Arbeits- und Verkehrssicherheit gefährdet, sollten Sie sich unbedingt ärztlich unterstützen und beraten lassen."
            ], 
            "score": {
                "min": 11, 
                "max": 15
            }
        },
        "high": {
            "label": "ausgeprägte Tagesschläfrigkeit",
            "labelShort": "ausgeprägt",
            "img": "ess-result-high",
            "text": [
                "Tagesmüdigkeit wird sehr unterschiedlich empfunden: Betroffene klagen häufig über Schlappheit, Mangel an Energie, Erschöpfung und Schläfrigkeit. Tagesmüdigkeit kann viele Gründe haben. Der Schlaf spielt eine entscheidende Rolle, aber auch Erkrankungen, Medikamente oder psychische Belastungen können zu Erschöpfung und Müdigkeit am Tag führen. Die Lage der Arbeitszeit kann das Risiko für Tagesmüdigkeit erhöhen: für Beschäftigte, die früh morgens oder nachts arbeiten müssen, ist es besonders wichtig, auf ausreichenden Schlaf zu achten.",
                "Versuchen Sie auch bei Schichtarbeit durchschnittlich sieben Stunden innerhalb eines 24-Stunden Tages zu schlafen. Der Zeitstrahl für Ihren Tagesablauf mit geeigneten Schlafzeiten und die Tipps zum besseren Schlafen können Ihnen dabei helfen. Möglicherweise hilft Ihnen vorübergehend an manchen Tagen ein kurzes Nickerchen.",
                "Es gibt Erkrankungen, die die Erholung während des Schlafs stören. Dazu gehören beispielsweise nächtliche Atemaussetzer, die Betroffene selbst nicht bemerken. Eine ausgeprägte Tagesmüdigkeit kann die Arbeits- und Verkehrssicherheit gefährden und Gesundheit und Wohlbefinden beeinträchtigen.",
                "Versuchen Sie, die Tipps rund um das Thema Schlaf zu beherzigen und die Ursache(n) für Ihre Tagesmüdigkeit herauszufinden. Lassen Sie sich dazu unbedingt (betriebs-) ärztlich unterstützen und beraten."
            ], 
            "score": {
                "min": 16, 
                "max": 24
            }
        }
    },
    "RIS": {
        "name": "Schlafqualität",
        "none": {
            "label": "gute Schlafqualität",
            "labelShort": "gut",
            "img": "ris-result-none",
            "text": ["Vermutlich fühlen Sie sich tagsüber erholt und ausgeschlafen. Damit das so bleibt, schlafen Sie ausreichend: es sollten durchschnittlich mindestens sieben Stunden innerhalb eines 24-Stunden-Tages sein. Prüfen Sie auch, welche Tipps für besseren Schlaf Sie bereits umsetzen. Berücksichtigen Sie dabei auch Ihr Schlafbedürfnis und Ihren Chronotyp. Denken Sie daran, sich draußen bei Tageslicht ausreichend zu bewegen."], 
            "score": {
                "min": 0, 
                "max": 12
            }
        },
        "mid": {
            "label": "mittlere Schlafqualität",
            "labelShort": "mittel",
            "img": "ris-result-mid",
            "text": [
                "Sie sind mit Ihrem Schlaf nicht immer zufrieden. Gönnen Sie sich ausreichend Schlaf, sieben bis acht Stunden innerhalb eines 24-Stunden-Tages sollten es sein.",
                "Ausreichend Tageslicht im Freien macht Sie tagsüber wacher und abends müde. Optimal ist Bewegung unter freiem Himmel, zum Beispiel auf Ihrem Weg von und zur Arbeit. Schauen Sie sich die Empfehlungen für besseren Schlaf an und versuchen Sie gleich heute, eine oder mehrere davon umzusetzen. Innerhalb eines 24-Stunden-Zeitraumes sollten Sie die Zeit im Sitzen auf maximal acht Stunden beschränken, da jede Art von Bewegung Ihren Schlaf fördert."
            ], 
            "score": {
                "min": 13, 
                "max": 24
            }
        },
        "high": {
            "label": "schlechte Schlafqualität",
            "labelShort": "schlecht",
            "img": "ris-result-high",
            "text": [
                "Bei dauerhaftem Schlafmangel steigt zum Beispiel das Unfallrisiko am Arbeitsplatz und unterwegs, Herz-Kreislauf-Erkrankungen nehmen zu und die Immunabwehr wird geschwächt. Häufig werden dauerhafter Schlafmangel und seine Folgen wie fehlende Konzentration und mangelndes Wohlbefinden gar nicht mehr wahrgenommen. Eine schlechte Schlafqualität sollten Sie daher ernst nehmen.",
                "Es gibt Erkrankungen und andere Ursachen wie Stress, Schmerzen oder Trauer, die die Schlafqualität beeinträchtigen können. Auch die Lage der Arbeitszeit kann sich auf den Schlaf auswirken. Versuchen Sie die Tipps für besseren Schlaf zu beherzigen.",
                "Erholsamer Schlaf lässt sich zudem nicht erzwingen - er gelingt in entspanntem Zustand am besten. Ganz ohne Disziplin geht es jedoch nicht. Falls Ihnen unsere Tipps nicht helfen und Ihre Schlafqualität seit mehr als 3 Monaten leidet, sollten Sie sich (betriebs-)ärztlich unterstützen und beraten lassen. Zum Beispiel können in einem Schlaflabor verschiedene Schlaferkrankungen erkannt werden."
            ], 
            "score": {
                "min": 25, 
                "max": 40
            }
        }
    },
    "CSM": {
        "name": "Chronotyp",
        "fruehtyp": {
            "label": "Frühtyp (Lerche)",
            "labelShort": "Frühtyp",
            "img": "csm-result-frueh",
            "text": ["Ihre innere Uhr läuft etwas schneller als ein 24-Stunden-Tag. Sie sind ein Frühtyp. Frühtypen oder 'Lerchen' sind morgens früh wach. Langes Aufbleiben am Abend bis in die Nacht fällt Ihnen vermutlich eher schwer. Ein günstiger Schlafzeitraum liegt für Sie zwischen 21 Uhr und 7 Uhr. Wenn Sie Schlaf nachholen möchten, gehen Sie so früh wie möglich zu Bett. Versuchen Sie an freien Tagen einen regelmäßigen Schlaf-Wach-Rhythmus passend zu Ihrem Chronotyp zu pflegen."], 
            "optSleepTime": {
                "start": 21,
                "end": 7
            },
            "score": {
                "min": 44, 
                "max": 55
            },
            "arbeitszeit":{
                "frueh": {
                    "label": "Frühschicht",
                    "text": {
                        "explanatory": "Tageslicht bevorzugt am Vormittag (z. B. Pause draußen verbringen), Bewegung mindestens 1 Stunde täglich, ab 15:00 keine koffeinhaltigen Getränke, ab 17:00 kein Alkohol, kleines leichtes Abendessen bis 18 Uhr, helles (künstliches oder natürliches) Licht ab 19 Uhr meiden, spätestens 7,5 Stunden bevor Ihr Wecker klingelt zu Bett gehen, Kühler Raum, abgedunkelt oder Schlafmaske verwenden. Hauptmahlzeiten: Frühstück und Mittagessen.",
                        "lichtbewegung": "Tageslicht vormittags nutzen, z. B. Frühstückspause draußen verbringen oder Wege auf dem Betriebsgelände draußen zurücklegen, anstatt durch die Gebäude zu gehen; Treppen anstelle von Aufzügen benutzen; Wege am frühen Nachmittag möglichst zu Fuß oder mit dem Fahrrad zurücklegen im Sommer vor dem Sonnenuntergang eine Brille mit blaulicht-filternden Gläsern (orange Gläser) tragen, da helles Licht wachmacht; Gefährden Sie dabei nicht die Verkehrssicherheit!",
                        "mahlzeiten": "Hauptmahlzeiten sind Frühstück und Mittagessen: ein leichtes frühes Abendessen erleichtert das Ein- und Durchschlafen, weil die Verdauung dann nachts nicht mehr arbeiten muss.",
                        "schlaf": "So früh wie möglich schlafen gehen - spätestens 7,5 Stunden bevor der Wecker morgens klingelt"
                    },
                    "dos": "Genießen Sie die Zeit am Nachmittag mit Familie und Freunden.",
                    "donts": "Schlafen Sie nicht abends beim Fernsehen ein! Das vermindert den Schlafdruck und erschwert das anschließende Einschlafen im Bett.",
                    "probleme_mit": [
                        {
                            "name": "ersten Frühschicht",
                            "text": "Zwei Tage vor der ersten Frühschicht bereits früher zu Bett gehen und am Tag vor der Frühschicht früher aufstehen als sonst, um die Umstellung etwas vorwegzunehmen, kein Nickerchen am Nachmittag. Soviel Tageslicht wie möglich am Morgen oder Vormittag"
                        }
                    ],
                    "tagesablauf": [
                        {   
                            "name": "breakfast",
                            "type": "point",
                            "relation": "before",
                            "time": 0.5,
                            "duration": 0
                        },
                        {   
                            "name": "breakfast",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 2.5,
                            "duration": 0
                        },
                        {   
                            "name": "lunch",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 6.5,
                            "duration": 0
                        },
                        {   
                            "name": "noCoffein",
                            "type": "point", 
                            "relation": "after",
                            "time": 0.5,
                            "duration": 0
                        },
                        {   
                            "name": "sport",
                            "type": "period",
                            "relation": "after",
                            "time": 2,
                            "duration": 1
                        },
                        {   
                            "name": "dinner" ,
                            "type": "point", 
                            "relation": "after",
                            "time": 3.5,
                            "duration": 0
                        },
                        {   
                            "name": "noDayLight",
                            "type": "point",
                            "relation": "after",
                            "time": 4.5,
                            "duration": 0
                        },
                        {   
                            "name": "toBed" ,
                            "type": "period", 
                            "relation": "after",
                            "time": 6.5,
                            "duration": 7.5
                        }
                    ]
                },
                "spaet": {
                    "label": "Spätschicht",
                    "text": {
                        "explanatory": "Nickerchen (max. 30 min) mittags vor der Spätschicht machen, nach der Spätschicht gleich zu Bett gehen, da Sie als Frühtyp nur abends 'vorschlafen' aber nicht morgens 'nachschlafen' können, kühler Raum, abgedunkelt oder Schlafmaske verwenden. Hauptmahlzeiten: Frühstück und Mittagessen",
                        "lichtbewegung": "Tageslicht vormittags und mittags nutzen, z. B. Mittagspause draußen verbringen oder Wege auf dem Betriebsgelände draußen zurücklegen, anstatt durch die Gebäude zu gehen; Treppen anstelle von Aufzügen benutzen; Wege vormittags möglichst zu Fuß oder mit dem Fahrrad zurücklegen; Vor allem im Herbst und Winter bei kurzen Tagen hilft Tageslicht am Nachmittag länger wach zu bleiben",
                        "mahlzeiten": "Hauptmahlzeiten sind Frühstück und Mittagessen: ein leichtes Abendessen verringert Müdigkeit zum Ende der Spätschicht.",
                        "schlaf": "Gleich nach der Spätschicht zu Bett gehen, falls das morgendliche Ausschlafen nicht gelingt; Frühtypen schlafen abends und nachts besser als am späten Morgen; in Spätschichtphasen mindestens 8 Stunden schlafen, um versäumten Schlaf nachzuholen"
                    },
                    "dos": "Schlafen Sie sich nach der Spätschicht aus, frühstücken Sie ausgiebig, nutzen Sie die Zeit für sportliche Aktivität und genießen Sie den freien Vormittag, das ist Ihr 'Feierabend'.",
                    "donts": "Essen Sie keine üppige Mahlzeit und keine fetten Snacks nach der Spätschicht. Bleiben Sie nach der Spätschicht nicht lange auf und verzichten Sie auf anregende Aktivitäten.",
                    "probleme_mit": [
                        {
                            "name": "ersten Spätschicht",
                            "text": "Wenn möglich, einen Tag vor der ersten Spätschicht länger aufbleiben und morgens ausschlafen, um die Umstellung vorwegzunehmen oder früh aufstehen und ein Nickerchen vor Schichtbeginn einplanen; Bei Müdigkeit zu Schichtbeginn die Hauptmahlzeit früher am Tag einnehmen: ein üppiges Mittagessen kann zu anschließender Müdigkeit führen."
                        }
                    ],
                    "tagesablauf":[
                        {   
                            "name": "breakfast", 
                            "type": "point",
                            "relation": "before",
                            "time": 5.5,
                            "duration": 0
                        },
                        {
                            "name": "sport",
                            "type": "period",
                            "relation": "before",
                            "time": 2.5,
                            "duration": 1
                        },
                        {   
                            "name": "lunch",
                            "type": "point",
                            "relation": "before",
                            "time": 1,
                            "duration": 0
                        },
                        {   
                            "name": "noCoffein",
                            "type": "point", 
                            "relation": "afterStart",
                            "time": 4,
                            "duration": 0
                        },
                        {   
                            "name": "dinner", 
                            "type": "point",
                            "relation": "afterStart",
                            "time": 6,
                            "duration": 0.5
                        },
                        {   
                            "name": "noDayLight",
                            "type": "point", 
                            "relation": "afterStart",
                            "time": 6,
                            "duration": 0
                        },
                        {   
                            "name": "toBed" ,
                            "type": "period", 
                            "relation": "after",
                            "time": 1,
                            "duration": 8
                        }
                    ]
                },
                "nacht": {
                    "label": "Nachtschicht",
                    "text": {
                        "explanatory": "Versuchen Sie, den Schlaf auf zwei 'Blöcke' zu verteilen: 4 bis 5 h direkt nach der Nachtschicht und 1 bis 2 h Nachmittagsschlaf vor bzw. zwischen zwei Nachtschichten, Tageslicht und Bewegung am späten Nachmittag oder abends solange es hell ist. Im Winter könnte Ihnen am Abend eine 30-minütige helle Beleuchtung  mit Tageslichtspektrum helfen, länger wach zu bleiben. Nachts wenig essen und nur, wenn Sie sehr hungrig sind, ab 2:00 Uhr nachts keine koffeinhaltigen Getränke, auf dem Heimweg im Sommer tragen Sie eine möglichst dunkle Sonnenbrille  (helles Licht macht wach), kleines Frühstück falls nötig (keine koffeinhaltigen Getränke), kühler Raum, abgedunkelt oder Schlafmaske verwenden, Hauptmahlzeiten: Mittagessen und Abendessen.",
                        "lichtbewegung": "Tageslicht am späten Nachmittag oder abends - solange es hell ist - nutzen, z. B. Wege auf dem Betriebsgelände draußen zurücklegen anstatt durch die Gebäude zu gehen, Treppen anstelle von Aufzügen benutzen; Bewegung und frische Luft machen munter, gerade, wenn Sie sich sehr schläfrig fühlen; Im Winter kann am Abend eine halbstündige kaltweiße Beleuchtung helfen, länger wach zu bleiben; Im Sommer nach Sonnenaufgang auf dem Heimweg eine Brille mit blaulicht-filternden Gläsern tragen (orange Gläser), da helles Licht wach macht. Gefährden Sie dabei nicht die Verkehrssicherheit!",
                        "mahlzeiten": "Hauptmahlzeiten sind Mittagessen und Abendessen: eine kleine leichte warme Mahlzeit vor dem nächtlichen Tiefpunkt hilft, diesen zu überwinden.",
                        "schlaf": "Schlaf, wenn möglich, auf zwei Blöcke verteilen mit vier bis fünf Stunden direkt nach der Nachtschicht und ein bis zwei Stunden Nachmittagsschlaf vor der nächsten Nachtschicht"
                    },
                    "dos": "Genießen Sie ein gemeinsames Mittagessen und einen freien Nachmittag mit Familie und Freunden.",
                    "donts": "Essen Sie nachts keine üppige Mahlzeit und keine fetten Snacks.",
                    "probleme_mit": [
                        {
                            "name": "ersten Nachtschicht",
                            "text": "Einen Tag vor der ersten Nachtschicht länger aufbleiben und morgens ausschlafen, um die Umstellung etwas vorwegzunehmen; am ersten Nachtschichttag nachmittags ein bis zwei Stunden schlafen; mindestens eine Stunde Bewegung bei Tageslicht nachmittags oder im Sommer abends;"
                        },
                        {
                            "name": "letzten Nachtschicht",
                            "text": "Wenn möglich, nach der letzten Nachtschicht nur vier bis fünf Stunden schlafen; Nickerchen nachmittags nur falls nötig und höchstens eine halbe Stunde in einem hellen Raum; abends so früh wie möglich zu Bett gehen, um versäumten Schlaf nachzuholen und die innere Uhr wieder auf den Tag-Nacht-Rhythmus umzustellen;"
                        }
                    ],
                    "tagesablauf":
                    [
                        {
                            "name": "meal",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 4.5,
                            "duration": 0
                        },
                        {
                            "name": "noCoffein",
                            "type": "point", 
                            "relation": "afterStart",
                            "time": 5.5,
                            "duration": 0
                        },
                        {
                            "name": "toBed",
                            "type": "period", 
                            "relation": "after",
                            "time": 0.5,
                            "duration": 5
                        },
                        {   
                            "name": "lunch",
                            "type": "point", 
                            "relation": "after",
                            "time": 6.5,
                            "duration": 0
                        },
                        {   
                            "name": "toBed",
                            "type": "period", 
                            "relation": "after",
                            "time": 7.5,
                            "duration": 2
                        },
                        {    
                            "name": "sport", 
                            "type": "period",
                            "relation": "after",
                            "time": 10.5,
                            "duration": 1
                        },
                        {   
                            "name": "dinner", 
                            "type": "point",
                            "relation": "after",
                            "time": 12.5,
                            "duration": 0.5
                        },
                        {   
                            "name": "dayLight",
                            "type": "point",
                            "relation": "after",
                            "time": 12.5,
                            "duration": 0
                        }
                    ]
                }
            }
        },
        "mittlerertyp": {
            "label": "Mittlerer Typ",
            "labelShort": "mittlerer Chronotyp",
            "img": "csm-result-mittel",
            "text": ["Der mittlere oder Normaltyp ist am flexibelsten in der Schlafenszeit. Ein günstiger Schlafzeitraum liegt etwa zwischen 22:00 und 9:00 Uhr. Finden Sie heraus, wann Sie Schlaf am besten nachholen können: abends durch früheres Zu-Bett-Gehen oder morgens durch längeres Ausschlafen. Vielleicht gelingt Ihnen auch beides. Versuchen Sie an freien Tagen einen regelmäßigen Schlaf-Wach-Rhythmus passend zu Ihrem Chronotyp zu pflegen."],
            "optSleepTime": {
                "start": 22,
                "end": 9
            },
            "score": {
                "min": 32, 
                "max": 43
            },
            "arbeitszeit":{
                "frueh": {
                    "label": "Frühschicht",
                    "text": {
                        "explanatory": "Tageslicht bevorzugt am Vormittag (z. B. Pause draußen verbringen), Bewegung mindestens 1 Stunde täglich, ab 15:00 keine koffeinhaltigen Getränke, ab 18:00 kein Alkohol, leichtes Abendessen bis 19 Uhr,  helles (künstliches oder natürliches) Licht ab 20 Uhr meiden, spätestens 7 Stunden bevor Ihr Wecker klingelt zu Bett gehen, Kühler Raum, abgedunkelt oder Schlafmaske verwenden. Hauptmahlzeiten: Frühstück und Mittagessen.",
                        "lichtbewegung": "Tageslicht vormittags nutzen, z. B. Frühstückspause draußen verbringen oder Wege auf dem Betriebsgelände draußen zurücklegen, anstatt durch die Gebäude zu gehen; Treppen anstelle von Aufzügen benutzen; Im Sommer vor Sonnenuntergang eine Brille mit blaulichtfilternden Gläsern (orange Gläser ) tragen, da helles Licht wach macht; Gefährden Sie dabei nicht die Verkehrssicherheit!",
                        "mahlzeiten": "Hauptmahlzeiten sind Frühstück und Mittagessen: ein leichtes frühes Abendessen erleichtert das Ein- und Durchschlafen, weil die Verdauung dann nachts nicht mehr arbeiten muss.",
                        "schlaf": "So früh wie möglich schlafen gehen - spätestens 7 Stunden bevor der Wecker morgens klingelt."
                    },
                    "dos": "Genießen Sie die Zeit am Nachmittag mit Freunden und Familie.",
                    "donts": "Schlafen Sie nicht abends beim Fernsehen ein! Das vermindert den Schlafdruck und erschwert das anschließende Einschlafen im Bett.",
                    "probleme_mit": [
                        {
                            "name": "ersten Frühschicht",
                            "text": "Wenn möglich, zwei Tage vor der ersten Frühschicht bereits früher zu Bett gehen und am Tag vor der Frühschicht früher aufstehen als sonst, um die Umstellung etwas vorwegzunehmen; kein Nickerchen am Nachmittag; so viel Tageslicht wie möglich am Morgen oder Vormittag"
                        }
                    ],
                    "tagesablauf": [
                        {   
                            "name": "hotDrink",
                            "type": "point",
                            "relation": "before",
                            "time": 0,
                            "duration": 0
                        },
                        {   
                            "name": "breakfast",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 2.5,
                            "duration": 0
                        },
                        {   
                            "name": "lunch",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 7,
                            "duration": 0
                        },
                        {   
                            "name": "noCoffein",
                            "type": "point",
                            "relation": "after",
                            "time": 1.5,
                            "duration": 0
                        },
                        {   
                            "name": "sport", 
                            "type": "period",
                            "relation": "after",
                            "time": 2,
                            "duration": 1
                        },
                        {   
                            "name": "dinner",
                            "type": "point",
                            "relation": "after",
                            "time": 4,
                            "duration": 0
                        },
                        {   
                            "name": "noDayLight",
                            "type": "point",
                            "relation": "after",
                            "time": 5.5,
                            "duration": 0
                        },
                        {   
                            "name": "toBed", 
                            "type": "period",
                            "relation": "after",
                            "time": 7.5,
                            "duration": 7
                        }
                    ]
                },
                "spaet": {
                    "label": "Spätschicht",
                    "text": {
                        "explanatory": "Entweder morgens nach der Schicht ausschlafen oder abends nach der Spätschicht direkt zu Bett gehen,  kühler Raum,  abgedunkelt oder Schlafmaske verwenden. Hauptmahlzeiten: Frühstück und Mittagessen",
                        "lichtbewegung": "Tageslicht vormittags und mittags nutzen; z. B. Wege auf dem Betriebsgelände draußen zurücklegen, anstatt durch die Gebäude zu gehen; Treppen anstelle von Aufzügen benutzen; Wege möglichst zu Fuß oder mit dem Fahrrad zurücklegen; Vor allem im Herbst und Winter bei kurzen Tagen hilft Tageslicht am Nachmittag, länger wach zu bleiben",
                        "mahlzeiten": "Hauptmahlzeiten sind Frühstück und Mittagessen: ein leichtes Abendessen verringert die Müdigkeit zum Ende der Spätschicht",
                        "schlaf": "Entweder morgens länger ausschlafen oder abends gleich nach der Spätschicht zu Bett gehen; In den Spätschichtphasen mindestens 8 Stunden schlafen, um versäumten Schlaf nachzuholen"
                    },
                    "dos": "Schlafen Sie sich nach der Spätschicht aus, frühstücken Sie ausgiebig, nutzen Sie die Zeit für sportliche Aktivität und genießen Sie den freien Vormittag, das ist Ihr 'Feierabend'. ",
                    "donts": "Essen Sie keine üppige Mahlzeit und keine fetten Snacks nach der Spätschicht. Bleiben Sie nach der Spätschicht nicht lange auf und verzichten Sie auf anregende Aktivitäten.",
                    "probleme_mit": [
                        {
                            "name": "ersten Spätschicht",
                            "text": "Wenn möglich, einen Tag vor der ersten Spätschicht länger aufbleiben und morgens ausschlafen, um die Umstellung vorwegzunehmen oder früh aufstehen und ein Nickerchen vor Schichtbeginn einplanen"
                        }
                    ],
                    "tagesablauf": [
                        {   
                            "name": "breakfast", 
                            "type": "point",
                            "relation": "before",
                            "time": 5,
                            "duration": 0
                        },
                        {   
                            "name": "sport", 
                            "type": "period",
                            "relation": "before",
                            "time": 2.5,
                            "duration": 1
                        },
                        {   
                            "name": "lunch", 
                            "type": "point",
                            "relation": "before",
                            "time": 1,
                            "duration": 0
                        },
                        {   
                            "name": "noCoffein",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 4.5,
                            "duration": 0
                        },
                        {   
                            "name": "dinner",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 6.5,
                            "duration": 0
                        },
                        {   
                            "name": "noDayLight",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 8.5,
                            "duration": 0
                        },
                        {   
                            "name": "toBed", 
                            "type": "period",
                            "relation": "after",
                            "time": 1.5,
                            "duration": 8
                        }
                    ]
                },
                "nacht": {
                    "label": "Nachtschicht",
                    "text": {
                        "explanatory": "Sie haben zwei Möglichkeiten: 1. Sie teilen Ihren Schlaf auf zwei 'Blöcke' auf: 4 bis 5 h direkt nach der Nachtschicht und 1 bis 2 h Nachmittagsschlaf vor bzw. zwischen zwei Nachtschichten, 2.5 bis 6 Stunden Schlaf direkt nach der Nachtschicht und ein Nickerchen am Nachmittag/frühen Abend. Tageslicht und 1 h Bewegung am späten Nachmittag oder abends solange es hell ist. Im Winter könnte Ihnen am Abend eine 30-minütige helle Beleuchtung  mit Tageslichtspektrum helfen, länger wach zu bleiben. Nachts eine kleine Mahlzeit, ab 2:00 Uhr nachts keine koffeinhaltigen Getränke, auf dem Heimweg im Sommer tragen Sie eine möglichst dunkle Sonnenbrille (helles Licht macht wach), kühler Raum, abgedunkelt oder Schlafmaske verwenden, Hauptmahlzeiten: Mittagessen und Abendessen.",
                        "lichtbewegung": "Tageslicht am späten Nachmittag oder abends nutzen, so lange es hell ist und mit Bewegung draußen verbinden, z. B. Wege auf dem Betriebsgelände draußen zurücklegen anstatt durch die Gebäude zu gehen, Treppen anstelle von Aufzügen benutzen; Bewegung und frische Luft machen munter, gerade, wenn Sie sich sehr schläfrig fühlen; Im Winter könnte am Abend eine halbstündige helle kaltweiße Beleuchtung helfen, länger wach zu bleiben; Im Sommer nach Sonnenaufgang auf dem Heimweg eine Brille mit blaulicht-filternden Gläsern (orange Gläser) tragen, da helles Licht wach macht. Gefährden Sie nicht die Verkehrssicherheit!",
                        "mahlzeiten": "Hauptmahlzeiten sind Mittagessen und Abendessen: eine leichte warme Mahlzeit vor dem nächtlichen Tiefpunkt hilft, diesen zu überwinden.",
                        "schlaf": "Entweder in zwei 'Blöcken' schlafen mit vier bis fünf Stunden direkt nach der Nachtschicht und ein bis zwei Stunden Nachmittagsschlaf vor der nächsten Nachtschicht oder fünf bis sechs Stunden Schlaf direkt nach der Nachtschicht und ein Nickerchen am Nachmittag oder frühen Abend vor der nächsten Nachtschicht"
                    },
                    "dos": "Nutzen Sie die Zeit nachmittags und abends für Bewegung im Freien. Genießen Sie ein ausgiebiges Abendessen mit Familie und Freunden - es darf auch mal üppig ausfallen.",
                    "donts": "Essen Sie keine üppige Mahlzeit und keine fetten Snacks nach Mitternacht.",
                    "probleme_mit": [
                        {
                            "name": "ersten Nachtschicht",
                            "text": "Wenn möglich, einen Tag vor der ersten Nachtschicht länger aufbleiben und morgens ausschlafen, um die Umstellung etwas vorwegzunehmen; am ersten Nachtschichttag nachmittags ein Nickerchen machen und eine Stunde Bewegung bei Tageslicht nachmittags oder im Sommer abends"
                        },
                        {
                            "name": "letzten Nachtschicht",
                            "text": "Wenn möglich, nach der letzten Nachtschicht vier bis fünf Stunden schlafen, Nickerchen nachmittags nur falls nötig und höchstens eine halbe Stunde in einem hellen Raum - das erleichtert das Aufwachen; abends so früh wie möglich zu Bett gehen, um versäumten Nachtschlaf nachzuholen und die innere Uhr wieder auf den Tag-Nacht-Rhythmus umzustellen",
                        }
                    ],
                    "tagesablauf": [
                        {   
                            "name": "meal", 
                            "type": "point",
                            "relation": "afterStart",
                            "time": 5,
                            "duration": 0
                        },
                        {   
                            "name": "noCoffein",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 6,
                            "duration": 0
                        },
                        {   
                            "name": "noDayLight",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 8,
                            "duration": 0
                        },
                        {   
                            "name": "toBed", 
                            "type": "period",
                            "relation": "after",
                            "time": 0.5,
                            "duration": 5.5
                        },
                        {   
                            "name": "lunch", 
                            "type": "point",
                            "relation": "after",
                            "time": 6.5,
                            "duration": 0
                        },
                        {   
                            "name": "toBed", 
                            "type": "period",
                            "relation": "after",
                            "time": 7.5,
                            "duration": 1.5
                        },
                        {   
                            "name": "sport", 
                            "type": "period",
                            "relation": "after",
                            "time": 9.5,
                            "duration": 1
                        },
                        {   
                            "name": "dinner",
                            "type": "point",
                            "relation": "after",
                            "time": 12.5,
                            "duration": 0
                        },
                        {   
                            "name": "dayLight",
                            "type": "point",
                            "relation": "after",
                            "time": 12.5,
                            "duration": 0
                        }
                    ]
                }
            }
        },
        "spaettyp": {
            "label": "Abendtyp (Eule)",
            "labelShort": "Abendtyp",
            "img": "csm-result-spaet",
            "text": ["Abendtypen oder 'Eulen' sind eher abends fit. Es fällt ihnen schwer, morgens früh aufzustehen. Ein günstiger Schlafzeitraum liegt zwischen 24:00 und 11:00 Uhr. Tageslicht am Vormittag hilft Ihnen, abends früher einzuschlafen. Wenn Sie Schlaf nachholen möchten, schlafen Sie an freien Tagen morgens länger aus. Versuchen Sie an freien Tagen einen regelmäßigen Schlaf-Wach-Rhythmus passend zu Ihrem Chronotyp zu pflegen."], 
            "optSleepTime": {
                "start": 24,
                "end": 11
            },
            "score": {
                "min": 13, 
                "max": 31
            },
            "arbeitszeit":{
                "frueh": {
                    "label": "Frühschicht",
                    "text": {
                        "explanatory": "Tageslichtmorgens und vormittag (z. B. Pause draußen verbringen), täglich 1 Stunde Bewegung, ab 16 Uhr keine koffeinhaltigen Getränke, ab 18 Uhr kein Alkohol, kleines leichtes Abendessen bis 19 Uhr, Helles Licht ab 19 Uhr meiden, spätestens 6,5 Stunden bevor Ihr Wecker klingelt zu Bett gehen, Raum abdunkeln oder Schlafmaske verwenden, Hauptmahlzeiten: Frühstück und Mittagessen.",
                        "lichtbewegung": "Tageslicht morgens und vormittags nutzen, z. B. Frühstückspause draußen verbringen und Wege auf dem Betriebsgelände draußen zurücklegen, anstatt durch die Gebäude zu gehen, Treppen anstelle von Aufzügen benutzen; Im Sommer vor Sonnenuntergang eine blaulicht-filternde Brille tragen (orange Gläser), da helles Licht wachmacht; Gefährden Sie dabei nicht die Verkehrssicherheit!",
                        "mahlzeiten": "Hauptmahlzeiten sind Frühstück und Mittagessen: ein leichtes frühes Abendessen erleichtert das Ein- und Durchschlafen, weil die Verdauung dann nachts nicht mehr arbeiten muss.",
                        "schlaf": "So früh wie möglich schlafen gehen und in den zwei Stunden davor unbedingt helles Licht meiden; Nickerchen nach der Frühschicht in einem hellen Raum und so kurz wie möglich machen; um abends noch ausreichend müde zu sein"
                    },
                    "dos": "Schlafen Sie nach der letzten Frühschicht richtig aus.",
                    "donts": "Schlafen Sie nicht abends beim Fernsehen ein! Das vermindert den Schlafdruck und erschwert das anschließende Einschlafen im Bett.",
                    "probleme_mit": [
                        {
                            "name": "ersten Frühschicht",
                            "text": "Wenn möglich, zwei Tage vor der ersten Frühschicht bereits früher zu Bett gehen als sonst; am Tag vor der ersten Frühschicht früher aufstehen als sonst, ausgiebig frühstücken und so viel Tageslicht wie möglich am Morgen und am Vormittag nutzen; kein Nickerchen am Nachmittag machen, um abends früher müde zu werden "
                        }
                    ],
                    "tagesablauf":[
                        {
                            "name": "hotDrink",
                            "type": "point",
                            "relation": "before",
                            "time": 0,
                            "duration": 0
                        },
                        {
                            "name": "breakfast",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 2.5,
                            "duration": 0
                        },
                        {
                            "name": "dayLight",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 2.5,
                            "duration": 0
                        },
                        {   
                            "name": "lunch",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 7,
                            "duration": 0
                        },
                        {   
                            "name": "nap",
                            "type": "period",
                            "relation": "after",
                            "time": 0.5,
                            "duration": 0.5
                        },
                        {
                            "name": "noCoffein",
                            "type": "point",
                            "relation": "after",
                            "time": 1.5,
                            "duration": 0
                        },
                        {   
                            "name": "sport",
                            "type": "period",
                            "relation": "after",
                            "time": 2,
                            "duration": 1
                        },
                        {
                            "name": "dinner",
                            "type": "point",
                            "relation": "after",
                            "time": 4.5,
                            "duration": 0
                        },
                        {
                            "name": "noDayLight",
                            "type": "point",
                            "relation": "after",
                            "time": 6,
                            "duration": 0
                        },
                        {   
                            "name": "toBed",
                            "type": "period",
                            "relation": "after",
                            "time": 8,
                            "duration": 6.5
                        }
                    ]
                },
                "spaet": {
                    "label": "Spätschicht",
                    "text": {
                        "explanatory": "Lange Ausschlafen, Hauptmahlzeiten: Mittagessen und Abendessen.  Falls Sie im rückwärts rollierenden System nach der Spät- in die Frühschicht wechseln müssen, gehen Sie direkt nach der Schicht zu Bett, um Ihren Rhythmus Richtung Frühschicht zu verschieben.",
                        "lichtbewegung": "Tageslicht vormittags und mittags nutzen und mit Bewegung verbinden; z. B. Pause draußen verbringen und Wege auf dem Betriebsgelände draußen zurücklegen, anstatt durch die Gebäude zu gehen, Treppen anstelle von Aufzügen benutzen; Wege möglichst zu Fuß oder mit dem Fahrrad zurücklegen; Vor allem im Herbst und Winter bei kurzen Tagen hilft Tageslicht, wach zu bleiben.",
                        "mahlzeiten": "Hauptmahlzeiten sind Frühstück und Abendessen",
                        "schlaf": "Mindestens 8 Stunden schlafen, um versäumten Schlaf nachzuholen"
                    },
                    "dos": "Wenn Sie von der Spät- in die Nachtschicht wechseln, können Sie nach der Spätschicht noch eine Weile aufbleiben, genießen Sie die nächtlichen Stunden für sich.",
                    "donts": "",
                    "probleme_mit": [
                        {
                            "name": "ersten Spätschicht",
                            "text": "Bei Müdigkeit zu Schichtbeginn die Hauptmahlzeit später am Tag einnehmen; ein üppiges Mittagessen kann zu anschließender Müdigkeit führen"
                        },
                        {
                            "name": "letzten Spätschicht",
                            "text": "Im rückwärts rollierenden System beim Wechsel von der Spät- in die Frühschicht direkt nach der Spätschicht zu Bett gehen, um den Tag-Nacht-Rhythmus Richtung Frühschicht zu verschieben"
                        }
                    ],
                    "tagesablauf":[
                        {
                            "name": "breakfast",
                            "type": "point",
                            "relation": "before",
                            "time": 3.5,
                            "duration": 0
                        },
                        {   
                            "name": "sport",
                            "type": "period",
                            "relation": "before",
                            "time": 1.5,
                            "duration": 1
                        },
                        {
                            "name": "lightlunch",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 1.5,
                            "duration": 0
                        },
                        {
                            "name": "noCoffein",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 5.5,
                            "duration": 0
                        },
                        {
                            "name": "dinner",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 5.5,
                            "duration": 0
                        },
                        {
                            "name": "noDayLight",
                            "type": "point",
                            "relation": "after",
                            "time": 0.5,
                            "duration": 0
                        },
                        {   
                            "name": "toBed",
                            "type": "period",
                            "relation": "after",
                            "time": 2.5,
                            "duration": 8.5
                        }
                    ]
                },
                "nacht": {
                    "label": "Nachtschicht",
                    "text": {
                        "explanatory": "Falls Sie nach der Nachtschicht keine 6 oder 7 Stunden am Stück schlafen können, probieren Sie aus, ob Sie Ihren Schlaf auf zwei 'Blöcke' aufteilen können: 4 bis 5 h direkt nach der Nachtschicht und 1 bis 2 h am Nachmittag vor der Nachtschicht. Ausschlafen, Tageslicht und Bewegung am späten Nachmittag oder abends solange es hell ist, Sonnenbrille morgens auf dem Heimweg tragen (helles Licht macht wach), ab 2:00 Uhr keine koffeinhaltigen Getränke, Hauptmahlzeiten: Mittagessen und Abendessen.",
                        "lichtbewegung": "Tageslicht am späten Nachmittag oder abends nutzen, so lange es hell ist und mit Bewegung draußen verbinden, z. B. Wege auf dem Betriebsgelände draußen zurücklegen anstatt durch die Gebäude zu gehen, Treppen anstelle von Aufzügen benutzen; Bewegung und frische Luft machen munter, gerade, wenn Sie sich sehr schläfrig fühlen; Im Winter könnte am Abend eine halbstündige helle kaltweiße Beleuchtung helfen, länger wach zu bleiben; Im Sommer nach Sonnenaufgang auf dem Heimweg eine Brille mit blaulicht-filternden Gläsern (orange Gläser) tragen, da helles Licht wach macht. Gefährden Sie nicht die Verkehrssicherheit!",
                        "mahlzeiten": "Hauptmahlzeiten sind Mittagessen und Abendessen, eine leichte warme Mahlzeit vor dem nächtlichen Tiefpunkt hilft, diesen zu überwinden; Nach der Nachtschicht bei Bedarf nur leicht Verdauliches zum Frühstück essen und auf koffeinhaltige Getränke (Kaffee, schwarzer Tee, Energydrinks) verzichten",
                        "schlaf": "Entweder nach der Nachtschicht sechs oder sieben Stunden am Stück schlafen oder Schlaf auf zwei 'Blöcke' aufteilen: vier bis fünf Stunden direkt nach der Nachtschicht und ein bis zwei Stunden am Nachmittag vor der Nachtschicht"
                    },
                    "dos": "Nutzen Sie den freien Nachmittag für Aktivitäten draußen und genießen Sie die Zeit mit Familie und Freunden.",
                    "donts": "Essen Sie keine üppige Mahlzeit und keine fetten Snacks in der zweiten Hälfte der Nachtschicht.",
                    "probleme_mit": [
                        {
                            "name": "ersten Nachtschicht",
                            "text": "Wenn möglich, einen Tag vor der ersten Nachtschicht länger aufbleiben und morgens ausschlafen, um die Umstellung etwas vorwegzunehmen, am ersten Nachtschichttag nachmittags ein Nickerchen und eine Stunde Bewegung bei Tageslicht nachmittags oder im Sommer abends"
                        },
                        {
                            "name": "letzten Nachtschicht",
                            "text": "Wenn möglich, nach der letzten Nachtschicht nur fünf bis sechs Stunden schlafen und möglichst kein Nickerchen nachmittags machen, falls unbedingt nötig, höchstens eine halbe Stunde in einem hellen Raum, abends so früh wie möglich zu Bett gehen, um die innere Uhr wieder auf den Tag-Nacht-Rhythmus umzustellen"
                        }
                    ],
                    "tagesablauf":[
                        {
                            "name": "meal",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 6,
                            "duration": 0
                        },
                        {
                            "name": "noCoffein",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 6.5,
                            "duration": 0
                        },
                        {
                            "name": "noDayLight",
                            "type": "point",
                            "relation": "afterStart",
                            "time": 8,
                            "duration": 0
                        },
                        {
                            "name": "breakfast",
                            "type": "point",
                            "relation": "after",
                            "time": 0.5,
                            "duration": 0
                        },
                        {   
                            "name": "toBed",
                            "type": "period",
                            "relation": "after",
                            "time": 1,
                            "duration": 6.5
                        },
                        {
                            "name": "lunch",
                            "type": "point",
                            "relation": "after",
                            "time": 8,
                            "duration": 0
                        },
                        {   
                            "name": "sport",
                            "type": "period",
                            "relation": "after",
                            "time": 11.5,
                            "duration": 1
                        },
                        {
                            "name": "dinner",
                            "type": "point",
                            "relation": "after",
                            "time": 13.5,
                            "duration": 0
                        },
                        {
                            "name": "nap",
                            "type": "period",
                            "relation": "after",
                            "time": 14,
                            "duration": 0.5
                        },
                        {
                            "name": "dayLight",
                            "type": "point",
                            "relation": "after",
                            "time": 14.5,
                            "duration": 0
                        }
                    ]
                }
            }
        }
    }
};

// inkl. Wegezeiten
export const Schichten = {
    "frueh": [
        {
            "start": 4.5,
            "end": 13.5
        },
        {
            "start": 5.5,
            "end": 14.5
        },
    ],
    "spaet": [
        {
            "start": 12.5,
            "end": 21.5
        },
        {
            "start": 13.5,
            "end": 22.5
        },
    ],
    "nacht": [
        {
            "start": 20.5,
            "end": 5.5
        },
        {
            "start": 21.5,
            "end": 6.5
        },
    ],
}