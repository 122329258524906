export function getQuestionnaireOpen(userDataResults, questionnaireData){
    var res = [];
    if(userDataResults.ESS.length !== questionnaireData.ESS.questions.length) res.push(questionnaireData['ESS'].nameshort);
    if(userDataResults.RIS.length !== questionnaireData.RIS.questions.length) res.push(questionnaireData['RIS'].nameshort);
    if(userDataResults.CSM.length !== questionnaireData.CSM.questions.length) res.push(questionnaireData['CSM'].nameshort);
    return res;
}

export function getQuestionnaireClosed(userDataResults, questionnaireData){
    var res = [];
    if(userDataResults.ESS.length === questionnaireData.ESS.questions.length) res.push(questionnaireData['ESS'].nameshort);
    if(userDataResults.RIS.length === questionnaireData.RIS.questions.length) res.push(questionnaireData['RIS'].nameshort);
    if(userDataResults.CSM.length === questionnaireData.CSM.questions.length) res.push(questionnaireData['CSM'].nameshort);
    return res;
}

export function getAllQuestionsAnswered(userDataResults, questionnaireData){
    const essDone = userDataResults.ESS.length === questionnaireData.ESS.questions.length;
    const risDone = userDataResults.RIS.length === questionnaireData.RIS.questions.length;
    const csmDone = userDataResults.CSM.length === questionnaireData.CSM.questions.length;
    return (essDone && risDone && csmDone) ? true : false;
}

export function schlafometerStarted(userDataResults){
    const essStarted = userDataResults.ESS.length > 0;
    const risStarted = userDataResults.RIS.length > 0;
    const csmStarted = userDataResults.CSM.length > 0;
    return (essStarted || risStarted || csmStarted) ? true : false;
}

export function generateRandId(){
    var S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    // return (S4());
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export const userDataDefault = {
    "uiData": {
        "ESS": [],
        "RIS": [],
        "CSM": []
    },
    "results": {
        "ESS": [],
        "RIS": [],
        "CSM": []
    },
    "arbeitszeit": null,
    "schicht": null,
    "ds-confirm": false,
    "alter": "",
    "sent": false,
    "activeView": "intro",
    "questionnaire": "ESS",
    "question": 0,
    "rating": "",
    "userId": String(generateRandId())
}

export const checkUrlParamData = (data) => {
    if(
      (data.arbeitszeit === undefined) ||
      (data.arbeitszeit.normal === undefined) ||
      (data.arbeitszeit.frueh === undefined) ||
      (data.arbeitszeit.spaet === undefined) ||
      (data.arbeitszeit.nacht === undefined) ||
      (data.arbeitszeit.sonstiges === undefined) ||
      (data.schicht === undefined) ||
      (data.schicht.frueh === undefined) ||
      (data.schicht.spaet === undefined) ||
      (data.schicht.nacht === undefined) ||
      (data.schicht.kein === undefined) ||
      (data["ds-confirm"] === undefined) ||
      (data.alter === undefined) ||
      (data.sent === undefined) ||
      (data.activeView === undefined) ||
      (data.questionnaire === undefined) ||
      (data.question === undefined) ||
      (data.results.length === 0) ||
      (data.uiData.length === 0)
    ){
      return false
    }
    
    return true
}

export function userDataToCsvData(userData, questionsData){
    let resData = []

    // id
    resData.push(userData.userId)

    // datum
    const dateArray = new Date().toJSON().slice(0,10).split(/-/g)
    const date = dateArray[0]+"-"+dateArray[1]+"-"+dateArray[2]
    resData.push(date)

    let essRes = 0;
    for (let i = 0; i < userData.results.ESS.length; i++) {
        let ess = userData.results.ESS[i];
        essRes += ess
        resData.push(ess)
    }
    
    if(userData.results.ESS.length !== questionsData.questionnaire.ESS.questions.length){
        let length = questionsData.questionnaire.ESS.questions.length - userData.results.ESS.length
        for (let i = 0; i < length+1; i++) {
            resData.push("") 
        }
    }
    else{
        resData.push(essRes)
    }

    let risRes = 0
    for (let i = 0; i < userData.results.RIS.length; i++) {
        let ris = userData.results.RIS[i];
        risRes += ris
        resData.push(ris)
    }
    
    if(userData.results.RIS.length !== questionsData.questionnaire.RIS.questions.length){
        let length = questionsData.questionnaire.RIS.questions.length - userData.results.RIS.length
        for (let i = 0; i < length+1; i++) {
            resData.push("") 
        }
    }
    else{
        resData.push(risRes)
    }

    let csmRes = 0
    for (let i = 0; i < userData.results.CSM.length; i++) {
        let csm = userData.results.CSM[i];
        csmRes += csm
        resData.push(csm)
    }
    
    if(userData.results.CSM.length !== questionsData.questionnaire.CSM.questions.length){
        let length = questionsData.questionnaire.CSM.questions.length - userData.results.CSM.length
        for (let i = 0; i < length+1; i++) {
            resData.push("") 
        }
    }
    else{
        resData.push(csmRes)
    }

    if(userData.arbeitszeit !== null){
        if(userData.arbeitszeit.normal !== undefined){
            resData.push((userData.arbeitszeit.normal) ? 1 : 0)
        }
        else{
            resData.push("")
        }
        if(userData.arbeitszeit.frueh !== undefined){
            resData.push((userData.arbeitszeit.frueh) ? 1 : 0)
        }
        else{
            resData.push("")
        }
        if(userData.arbeitszeit.spaet !== undefined){
            resData.push((userData.arbeitszeit.spaet) ? 1 : 0)
        }
        else{
            resData.push("")
        }
        if(userData.arbeitszeit.nacht !== undefined){
            resData.push((userData.arbeitszeit.nacht) ? 1 : 0)
        }
        else{
            resData.push("")
        }
        resData.push((userData.arbeitszeit.sonstiges !== "" || userData.arbeitszeit.sonstiges === "1" || userData.arbeitszeit.sonstiges === 1) ? 1 : 0)
        resData.push((userData.arbeitszeit.sonstiges !== "" || userData.arbeitszeit.sonstiges === "1" || userData.arbeitszeit.sonstiges === 1) ? userData.arbeitszeit.sonstiges : "")
    }
    else {
        resData.push("")
        resData.push("")
        resData.push("")
        resData.push("")
        resData.push("")
    }

    if(userData.schicht !== null){
        if(userData.schicht.frueh !== undefined){
            resData.push((userData.schicht.frueh) ? 1 : 0)
        }
        else{
            resData.push("")
        }
        if(userData.schicht.spaet !== undefined){
            resData.push((userData.schicht.spaet) ? 1 : 0)
        }
        else{
            resData.push("")
        }
        if(userData.schicht.nacht !== undefined){
            resData.push((userData.schicht.nacht) ? 1 : 0)
        }
        else{
            resData.push("")
        }
        if(userData.schicht.kein !== undefined){
            resData.push((userData.schicht.kein) ? 1 : 0)
        }
        else{
            resData.push("")
        }
    }
    else{
        resData.push("")
        resData.push("")
        resData.push("")
        resData.push("")
    }
    resData.push(userData.alter)
    resData.push((userData.rating === "like") ? 1 : (userData.rating === "neutral") ? 2 : (userData.rating === "dislike") ? 3 : 0)

    return resData;
}

export async function implementStats(urlEnding) {
    let url = (urlEnding) ? window.location.href+urlEnding : window.location.href;
    fetch(url)
    .catch((e) => console.error(e));
    return true;
};