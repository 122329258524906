import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import ButtonRow from './ButtonRow'
import styles from './NavRow.module.scss'
import { Results, Questions } from '../../data/data'
import { getAllQuestionsAnswered, getQuestionnaireClosed } from '../../js/Tools'

const NavRow = (props) => {

    const questionnaireClosed = getQuestionnaireClosed(props.userData.results, Questions.questionnaire);
    const questionsDone = getAllQuestionsAnswered(props.userData.results, Questions.questionnaire);

    const getNextQuestionnaire = () => {
        var keys = Object.keys(props.userData.results);
        var name = "";
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let questRes = props.userData.results[key];
            if(questRes.length < Questions.questionnaire[key].questions.length){
                let questName = Results[key].name;
                name = questName;
                break;
            }
        }
        return name;
    }
    const nextQuestionnaire = getNextQuestionnaire();

    useEffect(() => {
        // set Height of NavRow as padding-bottom
        var height = document.getElementById("navRow").offsetHeight;
        document.body.style.paddingBottom = height+"px";
        // set resize listener
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const handleResize = () => {
        var height = document.getElementById("navRow").offsetHeight;
        document.body.style.paddingBottom = height+"px";
    }

    useEffect(() => () => document.body.style.paddingBottom = "0", []);

    if(questionsDone){
        return (
            <div className={styles.navRow} id="navRow">
                <Container>
                    <Row className='d-flex align-items-center'>
                        <Col>
                            <span className={styles.questions}>Schlafometer abgeschlossen</span>
                            <p>Ihr Selbstcheck ist komplett.</p>
                        </Col>
                        <Col>
                            <Row className="align-items-center">
                                <Col className={styles.topicCol}>
                                    <span>Sehen Sie Ihre Schlafometer-Ergebnisse an</span>
                                </Col>
                                <Col className='d-flex justify-content-end align-items-center'>
                                    <ButtonRow className={styles.buttonRow}>
                                        <Button variant='primary' onClick={props.action} title='Ergebnisse ansehen'>Ergebnisse</Button>
                                    </ButtonRow>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    else{
        return (
            <div className={styles.navRow} id="navRow">
                <Container>
                    <Row className='d-flex align-items-center'>
                        <Col>
                            <span className={styles.questions}>{questionnaireClosed.length}/3&nbsp;Fragebögen abgeschlossen</span>
                            <p>Ihr Selbstcheck ist noch nicht komplett. Nutzen Sie auch die anderen Fragebögen für individuelle und detaillierte Ergebnisse.</p>
                        </Col>
                        <Col>
                            <Row className="align-items-center">
                                <Col className={styles.topicCol} md={6}>
                                    <span>Nächstes Thema:</span>
                                    <span className={styles.topic}>{nextQuestionnaire}</span>
                                </Col>
                                <Col className='d-flex justify-content-end' md={6}>
                                    <ButtonRow className={styles.buttonRow}>
                                        <Button variant='primary' onClick={props.action} title='Zum nächsten Fragebogen'>Weiter</Button>
                                    </ButtonRow>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default NavRow