import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer>
      <Container className={styles.footerLinks}>
      <Row>
        <Col className={styles.footerCol}>
          <ul>
            <li><a href="https://www.vbg.de/DE/Footer/Impressum/impressum_node.html" target="_blank" rel="noopener noreferrer" className={styles.footerLink}>Impressum</a></li>
            {/* <li><Link to="/impressum" className={styles.footerLink}>Impressum</Link></li> */}
            <li><Link to="/datenschutz" className={styles.footerLink}>Datenschutz</Link></li>
            <li><Link to="/barrierefreiheit" className={styles.footerLink}>Barrierefreiheit</Link></li>
            <li><Link to="/kontakt" className={styles.footerLink}>Kontakt</Link></li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
  )
}

export default Footer